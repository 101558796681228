import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import { Block, FeatureCTA } from '../../../components/styled';
import { breakpoints } from '../../../components/styled/variables';
import {
  Section,
  CustomerStoriesLinks,
  OverlayTrigger,
} from '../../../components';
import { Layout, SEO } from '../../../layout';
import KeyFigures from './key-figures';
import ArrowIcon from '../../products/assets/arrow.svg';

interface StoryProps {
  name: string;
  title: string;
  keyFigures: Record<string, any>;
  challengeElement: React.ReactNode;
  solutionElement: React.ReactNode;
  seoTitle?: string;
  subtitle?: string;
  companyElement?: React.ReactNode;
  challengeTitle?: string;
  solutionTitle?: string;
  heroSquare?: {
    background: string;
    content: React.ReactNode;
  };
  highlight?: React.ReactNode;
  highlightImgClassName?: string;
  quoteAuthor?: any;
  benefits?: any[];
}

const Story: React.FC<StoryProps> = ({
  name,
  title,
  seoTitle,
  subtitle,
  keyFigures,
  companyElement,
  challengeElement,
  challengeTitle,
  solutionElement,
  solutionTitle,
  heroSquare,
  highlight,
  highlightImgClassName,
  quoteAuthor,
  benefits,
}) => {
  const lowercaseName = name.toLowerCase();
  const logo = getAsset(`post_${lowercaseName}_logo`);
  const authorImg = quoteAuthor ? getAsset(quoteAuthor.avatar) : null;

  const { heroImages, contentImages } = useStaticQuery(assetsQuery);
  const hero = heroImages.edges.find(
    ({ node }) => node.name === `hero_${lowercaseName}@2x`,
  );

  const contentImg = contentImages.edges.find(
    ({ node }) => node.name === `content_${lowercaseName}@2x`,
  );

  return (
    <Layout>
      <SEO
        title={seoTitle || title}
        image={hero.node.childImageSharp.gatsbyImageData.src}
        prefix=""
      />
      <Section className="inner story-header">
        <HeroContainer>
          <div className={!heroSquare ? 'centered' : undefined}>
            <img
              className="story-logo"
              src={logo.src}
              srcSet={logo.srcSet}
              alt={name}
            />
            <p className="hero-text">{title}</p>
            <div className="divider" />
            <p className="story-subtitle">
              <em>{subtitle || 'Customer story'}</em>
            </p>
          </div>
          {heroSquare && (
            <HeroSquare background={heroSquare.background}>
              {heroSquare.content}
            </HeroSquare>
          )}
        </HeroContainer>
        <Block width="840px" margin="0 auto 50px">
          <GatsbyImage
            image={hero.node.childImageSharp.gatsbyImageData}
            alt={`${title} hero image`}
          />
        </Block>
        <Block width="740px" margin="0 auto">
          <KeyFigures keyFigures={keyFigures} />
        </Block>
      </Section>
      <Section className="narrow challenge-part">
        {companyElement}
        <h2 className="text-left">{challengeTitle || 'The challenge'}</h2>
        {challengeElement}
      </Section>
      <Section className="inner">
        <div
          className={`customer-highlight ${
            highlight ? 'two-columns' : 'max-600-above-tablet centered'
          } ${
            ['Perkbox', 'intu', 'Miconex'].includes(name)
              ? 'customer-hl-with-screenshot'
              : ''
          }`}
        >
          <div
            className={`cus-quote${
              name === 'Avios' || name === 'TopCashback' ? ' order-1' : ''
            }`}
          >
            <h3>{highlight}</h3>
            {quoteAuthor && (
              <div className="cus-quote-author">
                {authorImg && (
                  <img
                    src={authorImg.src}
                    srcSet={authorImg.srcSet}
                    role="presentation"
                  />
                )}
                <p>
                  <strong className="quote-author-name">
                    {quoteAuthor.name}
                  </strong>
                  <span className="quote-author-desc">
                    {quoteAuthor.description}
                  </span>
                </p>
              </div>
            )}
          </div>
          <GatsbyImage
            image={contentImg.node.childImageSharp.gatsbyImageData}
            alt={`${title}'s photo`}
            className={`cus-hl-img ${highlightImgClassName || ''}`}
          />
        </div>
      </Section>
      <Section className="narrow solution-element">
        <h2 className="text-left">{solutionTitle || 'The solution'}</h2>
        {solutionElement}
        {!benefits && (
          <FeatureCTA
            as={OverlayTrigger}
            target="contact_sales"
            id={`contactSales-story-${name.toLowerCase()}`}
            style={{ marginTop: '30px' }}
          >
            Want to learn more? <ArrowIcon />
          </FeatureCTA>
        )}
      </Section>
      {benefits && (
        <Section className="story-benefits">
          <h2>Key benefits</h2>
          <ul className="benefits with-thumbs">
            {benefits.map((benefit, idx) => (
              <li key={idx}>{benefit}</li>
            ))}
          </ul>
          <OverlayTrigger
            target="contact_sales"
            id={`Contact_Sales-Story-${name}`}
          >
            Want to learn more?
          </OverlayTrigger>
        </Section>
      )}
      <hr className="stories-footer-divider" />
      <CustomerStoriesLinks current={name} />
    </Layout>
  );
};

const HeroContainer = styled.div`
  display: flex;
  max-width: 840px;
  margin: 0 auto 40px;
  flex-direction: column;

  @media (min-width: ${breakpoints.tabletPortrait}) {
    flex-direction: row;
  }
`;

const HeroSquare = styled.div<{ background: string }>`
  background: ${({ background }) => background};
  width: 100%;
  color: white;
  padding: 40px;
  display: flex;
  align-items: center;

  h2 {
    font-size: 26px;
    margin: 0 0 16px;
  }

  p {
    color: white;
    &:first-of-type {
      margin-bottom: 32px;
    }
  }

  @media (min-width: ${breakpoints.tabletPortrait}) {
    margin-left: 40px;
  }
`;

const assetsQuery = graphql`
  {
    heroImages: allFile(filter: { relativePath: { regex: "/stories/hero/" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(width: 840, placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
    }
    contentImages: allFile(
      filter: { relativePath: { regex: "/stories/content/" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(width: 600, placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;

function getAsset(filename: string) {
  const src = require(`../../../modules/stories/images/${filename}.png`);
  const src2x = require(`../../../modules/stories/images/${filename}@2x.png`);
  const srcSet = `${src.default}, ${src2x.default} 2x`;

  return { src, srcSet };
}

export default Story;
