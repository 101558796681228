import React from 'react';

const KeyFigures: React.FC<Record<string, any>> = ({ keyFigures }) => (
  <dl className="key-figures">
    {Object.entries(keyFigures).map(([key, value]: any[]) => (
      <div key={key}>
        <dt>{key}</dt>
        <div className="decoration-line" />
        <dd>{value}</dd>
      </div>
    ))}
  </dl>
);

export default KeyFigures;
