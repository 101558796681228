import React from 'react';

import Story from './components/story';
import { Link } from '../../components';

export default function Miconex() {
  const props = {
    name: 'Miconex',
    title:
      'How Mi Rewards uses Fidel API to build citywide loyalty programmes and support local businesses',
    seoTitle:
      'How Mi Rewards uses Fidel API to build citywide loyalty programmes and support local businesses',
    subtitle: 'With Colin Munro, Managing Director at Miconex',
    heroSquare: {
      background: '#ca0a7d',
      content: (
        <div>
          <h2>The Goal</h2>
          <p>Drive greater spend to local SMEs.</p>
          <h2>The Results</h2>
          <p>
            Mi Rewards has driven over £900,000 in local spend and on average
            has increased the number of transactions on their platform by over
            31% quarterly.
          </p>
        </div>
      ),
    },
    heroImgAlt: '',
    keyFigures: {
      Industry: 'Loyalty and Rewards',
      Product: 'Transactions API',
      Region: 'UK',
    },
    companyElement: (
      <>
        <h2>Challenge</h2>
        <p>
          Miconex wanted to find a way to help support local businesses, while
          creating rewarding and seamless user experiences for shoppers.
        </p>
        <h2>Solution</h2>
        <p>
          With Fidel API, Miconex launched Mi Rewards, a loyalty programme that
          rewards users for shopping locally and drives spend for small, local
          businesses. Originally launched in Perth, Scotland in 2018, Mi Rewards
          has now expanded across the UK to a total of eight cities.
        </p>
      </>
    ),
    challengeTitle: 'A Question of Loyalty',
    challengeElement: (
      <>
        <div className="less-letter-spacing">
          <p>
            How do you create loyalty programmes that support local businesses,
            while keeping customers coming back? Colin Munro, Managing Director
            at Miconex, wanted to find the answer.
          </p>
          <p>
            Miconex runs local town and city-wide gift card programmes in the UK
            and Ireland. Their aim is to drive footfall into town and city
            centres, encourage the discovery of local businesses and stimulate
            additional economic activity.
          </p>
          <p>
            <i>“Defining loyalty is easy,”</i> Colin explains.{' '}
            <i>
              “It comes down to identifying the person at the point of sale in
              order to give them something extra - something to reward them for
              being loyal.”
            </i>{' '}
            But finding a way to do that was less straightforward.
          </p>
          <p>
            Colin wanted to stimulate an increase in spending for the many SMEs
            in Perth, Scotland via a customer reward-based solution that
            targeted smaller, localised shopping. However, it wasn’t an ambition
            without obstacles. Firstly, all the local businesses in Perth used
            different Point of Sale (POS) systems. Integrating with each of them
            individually would be expensive and complex and unconducive to
            increasing profits. Colin needed a solution that could easily
            integrate with a range of POS systems, without asking businesses to
            pay for, or maintain additional hardware.
          </p>
          <p>
            Colin understood that the key to driving traffic towards local
            businesses, lay with shoppers. So he set out to find a way to create
            frictionless and simple user experiences that reward shoppers every
            time they spend. This meant finding a solution that didn’t involve
            physical loyalty cards or coupons which are easily forgotten and
            inconvenient, especially when it comes to smaller transactions.
          </p>
        </div>
        <div>
          <h2 className="text-left">The Solution | A Citywide Solution</h2>
          <p>
            Miconex leveraged Fidel API’s Select Transactions API to build the
            city-wide loyalty programme{' '}
            <Link to="https://mi-rewards.com/">Mi Rewards</Link>. Initially
            launched across Perth in 2018, the programme was specifically
            designed to drive additional footfall to Perth’s local businesses.
          </p>
          <p>
            Local merchants could connect to Mi Rewards quickly and easily,
            through a single API – regardless of the POS system they used. It’s
            a simple integration that cuts cost, complexity and compliance
            headaches. An added benefit of Fidel API’s Select Transactions API
            is the spending insight available to merchants, allowing them to be
            more strategic about the rewards they offer, further supporting
            increased income generation.
          </p>
          <p>
            <i>
              “Local businesses now have a better understanding of the types of
              people spending money in their stores, what they’re spending money
              on, and what the economic impact of that is,”
            </i>{' '}
            Colin explains.{' '}
            <i>
              “With spend data, they’re able to measure the impact of different
              approaches and create loyalty by offering more relevant and timely
              rewards.”
            </i>{' '}
            Not to mention, there’s no need for staff training or additional
            software installations.
          </p>
          <p>
            For users, enrolling in Mi Rewards is fast and easy. Users simply
            download the app, add their payment card of choice and start
            automatically earning rewards every time they shop. Users don’t need
            to identify themselves every time they spend to earn points and
            receive real-time notifications of rewards or points earned.
          </p>
        </div>
      </>
    ),
    highlight:
      '“Fidel API have provided fantastic ongoing support for the project which has enabled us to quickly test this new smart city rewards programme. The data generated from this card-linked programme enables a new understanding of how consumers interact with cities!”',
    highlightImgAlt: '',
    quoteAuthor: {
      name: 'Colin Munro',
      description: 'Managing Director at Miconex',
      avatar: 'colin_munro',
    },
    solutionTitle: 'The Results | Continued Expansion and Scale',
    solutionElement: (
      <div>
        <p>
          After launching in Perth, Scotland in 2018, there are now{' '}
          <strong>
            80 local businesses and over 7% of the city’s adult population
            participating in the Mi Rewards programme.
          </strong>{' '}
          The success of the programme has enabled its{' '}
          <strong>
            expansion across the UK, with launches in seven additional cities
          </strong>{' '}
          (including Guildford, Stoke-on-Trent, Fleet, Barnsley and Enniskillen
          in 2020 alone).{' '}
          <strong>
            Over £900,000 has been spent through Mi Rewards to help support
            local businesses across the UK.
          </strong>
        </p>
        <p>
          For Colin and the team, key highlights include a{' '}
          <strong>
            138% increase in cards linked year on year and over 31% increase in
            the average number of transactions quarterly
          </strong>{' '}
          (September 2018- March 2021).
        </p>
        <p>
          The challenging global retail environment in 2020 was a strong
          indicator of the importance of stimulating spend during recovery
          periods. As the economy continues to recover and return to “normal,”
          programmes like Mi Rewards can help re-energise city centres that were
          locked down in 2020’s pandemic.
        </p>
        <p>
          Asked about the working relationship with Fidel API’s team, Colin
          explained:{' '}
          <i>
            “Fidel API has provided fantastic ongoing support for the project
            which has enabled us to quickly test our smart town and city rewards
            programme. The data generated from this card-linked programme
            enables a new understanding of how consumers interact with towns and
            cities.”
          </i>
        </p>
        <p>
          <i>“We have found a model that works”.</i>
        </p>
      </div>
    ),
  };

  return <Story {...props} />;
}
